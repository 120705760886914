import(/* webpackMode: "eager", webpackExports: ["Button"] */ "/home/runner/_work/web/web/components/ui/button.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/web/web/components/ui/field/standard-field.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/_work/web/web/components/ui/form/form.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/web/web/components/ui/input.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/web/web/components/ui/textarea.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/home/runner/_work/web/web/node_modules/.pnpm/next@15.1.3_@babel+core@7.24.5_@opentelemetry+api@1.9.0_@playwright+test@1.49.0_react-d_2de3c545f009e4964cff78dc9d0afded/node_modules/next/dist/client/app-dir/link.js");
